// Sizes and Margins
$document-padding: 20px
$inter-element-margin: 5px
$standard-border-radius: 5px
$side-menu-width: 350px
$color-indicator-width: 140px

$global-padding: 30px
$side-bar-width: 350px
$navbar-height: 50px
$navbar-padding: 10px

// Durations
$transition-length: 150ms

// Colors
$gray-very-dark: #5c5c5c
$gray-dark: #aaa
$gray-semi-dark: #bbb
$gray-semi-light: #ccc
$gray-light: #ddd
$gray-very-light: #eee
$blue: #00A6D6
$blue-dark: #0087b5
$blue-very-dark: #006182
$blue-light: #deebf7

// Media queries
$screen-sm: 768px
$screen-md: 992px
$screen-lg: 1200px
