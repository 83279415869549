=transition($property, $time)
    -webkit-transition: $property $time
    -moz-transition: $property $time
    -o-transition: $property $time
    transition: $property $time

=user-select
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

=border-radius($length)
    -webkit-border-radius: $length
    -moz-border-radius: $length
    border-radius: $length

/* General Button Abstractions */
=clickable
    cursor: pointer
    +user-select
