.rack-sidebar-container
    display: flex
    height: 100%
    max-height: 100%

.rack-sidebar-header-container
    flex: 0

.machine-list-container
    flex: 1
    overflow-y: scroll
