.jumbotron-header
    background: #00A6D6

.jumbotron
    background-color: inherit
    margin-bottom: 0

    padding-top: 120px
    padding-bottom: 120px

    img
        max-width: 110px

    h1
        color: #fff
        font-size: 4.5em

        .dc
            color: #fff
            font-weight: bold

    .lead
        color: #fff
        font-size: 1.4em
