.blinking-cursor
    -webkit-animation: 1s blink step-end infinite
    -moz-animation: 1s blink step-end infinite
    -o-animation: 1s blink step-end infinite
    animation: 1s blink step-end infinite

@keyframes blink
    from, to
        color: #eeeeee
    50%
        color: #333333

@-moz-keyframes blink
    from, to
        color: #eeeeee
    50%
        color: #333333

@-webkit-keyframes blink
    from, to
        color: #eeeeee
    50%
        color: #333333

@-ms-keyframes blink
    from, to
        color: #eeeeee
    50%
        color: #333333

@-o-keyframes blink
    from, to
        color: #eeeeee
    50%
        color: #333333
