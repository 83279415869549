.terminal-window
    width: 600px
    height: 400px
    display: block

    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0

    margin: auto

    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    cursor: default

    overflow: hidden

    box-shadow: 5px 5px 20px #444444

.terminal-header
    font-family: monospace
    background: #cccccc
    color: #444444
    height: 30px
    line-height: 30px
    padding-left: 10px

    border-top-left-radius: 7px
    border-top-right-radius: 7px

.terminal-body
    font-family: monospace
    text-align: center
    background-color: #333333
    color: #eeeeee
    padding: 10px

    height: 100%

.segfault
    text-align: left

.sub-title
    margin-top: 20px

.home-btn
    margin-top: 10px
    padding: 5px
    display: inline-block
    border: 1px solid #eeeeee
    color: #eeeeee
    text-decoration: none
    cursor: pointer

    -webkit-transition: all 200ms
    -moz-transition: all 200ms
    -o-transition: all 200ms
    transition: all 200ms

.home-btn:hover
    background: #eeeeee
    color: #333333

.home-btn:active
    background: #333333
    color: #eeeeee
