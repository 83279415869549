@import "~bootstrap/scss/bootstrap"

@import ./style-globals/_mixins.sass
@import ./style-globals/_variables.sass

html, body, #root
    margin: 0
    padding: 0
    width: 100%
    height: 100%

    font-family: Roboto, Helvetica, Verdana, sans-serif
    background: #eee

    // Scroll padding for top navbar
    scroll-padding-top: 60px

.full-height
    position: relative
    height: 100% !important

.page-container
    padding-top: 60px

.text-page-container
    padding-top: 80px
    display: flex
    flex-flow: column

.vertically-expanding-container
    flex: 1 1 auto
    overflow-y: auto

.bottom-btn-container
    flex: 0 1 auto
    padding: 20px 0

.btn, .list-group-item-action, .clickable
    +clickable

.btn-circle
    +border-radius(50%)

a, a:hover
    text-decoration: none

.app-page-container
    padding-left: $side-bar-width
    padding-top: 15px

.w-70
    width: 70% !important
