@import ../../style-globals/_mixins.sass
@import ../../style-globals/_variables.sass

.navbar
    border-top: $blue 3px solid
    border-bottom: $gray-semi-dark 1px solid
    color: $gray-very-dark
    background: #fafafb

.opendc-brand
    display: inline-block
    color: $gray-very-dark

    +transition(background, $transition-length)

    img
        position: relative
        bottom: 3px
        display: inline-block
        width: 30px

.login
    height: 40px
    background: $blue
    border: none
    padding-top: 10px
    +clickable

    &:hover
        background: $blue-dark
