.contact-section
    background-color: #444
    color: #ddd

    a
        color: #ddd

    a:hover
        color: #fff

    .tudelft-icon
        height: 100px

    .disclaimer
        color: #cccccc
