.not-found-backdrop
    position: absolute
    left: 0
    top: 0

    margin: 0
    padding: 0
    width: 100%
    height: 100%

    background-image: linear-gradient(135deg, #00678a, #008fbf, #00A6D6)
